import React, { useState, useEffect, useRef } from "react";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.css";
import './survey.css'
import { useParams } from "react-router-dom";
import { FunctionFactory } from "survey-core";
import { Container, Paper, Typography, CircularProgress } from '@mui/material';
import ErrorComponent from './ErrorComponent'
import { useNavigate } from 'react-router-dom';


function convertString(params) {
  return String(params);
}
FunctionFactory.Instance.register("convertString", convertString);

const DEBUG = process.env.REACT_APP_DEBUG || false;


function SurveyComponent() {


  const [surveyModel, setSurveyModel] = useState(null);
  const [eventVars, setEventVars] = useState(null);
  const [links, setLinks] = useState([]);
  const [designSettings, setDesignSettings] = useState(null);
  const [redirectUrl, setRedirectUrl] = useState('');
  const [clientStatus, setClientStatus] = useState('loading');
  const loadedScript = useRef(false);
  const [business, setBusiness] = useState(null);
  const [campaign, setCampaign] = useState(null);
  const navigate = useNavigate();






  const { urlid } = useParams(); //question_id_sd_uuid 1_fjksdfjksjdfk9
  const spiltedId = urlid.split('_sd_');//
  let questionId;
  if (spiltedId.length) {
    questionId = spiltedId[0];
  }
  const requestId = spiltedId.slice(1).join('');




  const loadScript = () => {

    const env = process.env.REACT_APP_ENV;
    const isProduction = env === 'production';
    const script = document.createElement('script');
    script.src = isProduction
      ? "https://analytics.uzeli.com/gz_analytics.min.js"
      : "https://analyticsstaging.uzeli.com/gz_analytics.min.js";
    script.async = false;
    if (DEBUG) console.log(script.src);

    script.onload = () => {
      console.log('GZAnalytics loaded', window.GZAnalytics);



    };

    script.onerror = () => {
      console.log('Failed to load the GZAnalytics script.');
    };

    document.body.appendChild(script);
    loadScript.current = true;
  };

  if (!loadedScript.current) loadScript();



  useEffect(() => {

    fetch('/geteventvars', {
      method: 'GET',
    }).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok', "response", response);
      }
      return response.json();
    })
      .then(response => {
        console.log("Loaded vars");
        if (DEBUG) console.log(response);

        const serverResponseData = response.serverResponse?.data;

        setEventVars(serverResponseData?.eventVars);
        setLinks(serverResponseData?.links);
        setRedirectUrl(serverResponseData?.redirectUrl || false)
        setDesignSettings(serverResponseData?.designSettings);
        setBusiness(serverResponseData?.business);
        setCampaign(serverResponseData?.campaign);
        setClientStatus(serverResponseData.status);

      })
      .catch(error => {
        setClientStatus('serverError');
        console.error("Error getting event variables..");
        if (DEBUG) console.log(error);
      });


  }, [urlid])





  useEffect(() => {
    const fetchData = async () => {
      try {

        const json = await import(`../public/surveys/j_${questionId}.json`);
        if (redirectUrl) {
          json.navigateToUrl = redirectUrl;
        }
        const theme = await import(`../public/surveys/t_${questionId}.json`);

        const survey = new Model(json);
        if (DEBUG) console.log("surveymodel", survey);
        survey.applyTheme(theme);
        const analytics = window.GZAnalytics;

        if (analytics) {

          analytics.init('survey-local');
          //analytics.init(`survey-${env}`);
          analytics.setBusinessData({

            businessLocationId: business.id,
            networkId: business.networkId,
            businessLocationName: business.name,


          });
          survey.onComplete.add((sender, options) => {



            if (analytics) {
              analytics.customLog({
                name: 'Survey Completed',
                eventtype: 'Survey Completed',
                payload: {
                  surveyCampaignName: campaign?.name,
                  surveyCampaignDescription: campaign?.description,

                  questionId: questionId,
                  questionTitle: sender.title,
                  questionDescription: sender.description,
                  totalPage: sender.pageCount,

                }
              })
            }

            const surveyData = { "formData": sender.data, "requestId": requestId, "questionId": questionId };
            const submitUrl = '/submit';
            fetch(submitUrl, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(surveyData),
            })
              .then(response => {
                if (!response.ok) {
                  throw new Error('Network response was not ok', "response", response);
                }
                return response.json();
              })
              .then(data => {
                console.log("Survey submitted successfully:", data);
                if (DEBUG) console.log(links);
                const brandName = survey.getVariable('BrandName');
                const businessName = survey.getVariable('businessName');
                if (DEBUG) console.log(brandName, businessName);

                if (!redirectUrl && links)
                  navigate('/thankyou', {
                    state: {
                      links: links,
                      LocationName: businessName,
                      brandName: brandName,
                      designSettings: designSettings,
                    }
                  });

              })
              .catch(error => {
                console.error("Error submitting survey:", error);
              });



          });



          survey.onCurrentPageChanged.add((sender, options) => {

            const newPage = options.newCurrentPage;
            const oldPage = options.oldCurrentPage;

            // console.log('newPage', newPage.num);
            // console.log('oldPage', oldPage.num);
            // console.log('added', newPage.num);
            if (analytics)
              analytics.customLog({
                name: 'Survey Page',
                eventtype: 'Next Button Clicked',
                payload: {
                  surveyCampaignName: campaign?.name,
                  surveyCampaignDescription: campaign?.description,
                  questionId: questionId,
                  questionTitle: sender.title,
                  questionDescription: sender.description,
                  totalPage: sender.pageCount,
                  newPageNo: newPage.num,
                  pageTitle: oldPage.title,
                  pageNo: oldPage.num,
                  pageDescription: oldPage.description,
                  pageNavigationTitle: oldPage.navigationTitle,
                  isStartPage: oldPage.isStartPage,
                  moved: options.isGoingForward ? 'forward' : 'backward',
                  noOfQuestions: oldPage.questions.length,
                  currentPageTitle: newPage.title,
                  //    questionsValues: oldPage.questions.map(q => q.value),


                }
              });
          });

          survey.onStarted.add((sender, options) => {
            if (analytics) {
              analytics.customLog({
                name: 'Survey Started',
                eventtype: 'Survey Started',
                payload: {
                  surveyCampaignName: campaign?.name,
                  surveyCampaignDescription: campaign?.description,
                  questionId: questionId,
                  questionTitle: sender.title,
                  questionDescription: sender.description,
                  totalPage: sender.pageCount,

                }
              })
            }
          })



        } else {
          console.log('GZAnalytics not available');
        }



        setSurveyModel(survey);
      } catch (error) {
        console.error("Failed to fetch survey data:", error);
      }
    };

    if (urlid && business)

      fetchData();
  }, [urlid, links, business]);






  function formatServicesData(services) {
    // Helper function to create different formats
    function createFormats(list) {
      return {
        commaList: list.join(", "),
        toLowercaseCommaList: list.join(", ").toLowerCase(),
        htmlBulletList: `<ul>${list
          .map((item) => `<li>${item}</li>`)
          .join("")}</ul>`,
        htmlNoBulletList: `<div>${list
          .map((item) => `<div>${item}</div>`)
          .join("")}</div>`,
        markdownList:
          list.length === 1
            ? list[0]
            : list.map((item) => ` - ${item}`).join("\n "),
      };
    }

    let categorizedServices = services.reduce((acc, service) => {
      if (!acc[service.category_name]) {
        acc[service.category_name] = {
          name: service.category_name,
          titleCase: service.category_name
            .split(" ")
            .map(
              (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            )
            .join(" "),
          services: [],
          fullServiceNames: [],
        };
      }
      acc[service.category_name].services.push(service.name);
      acc[service.category_name].fullServiceNames.push(
        service.category_name + " - " + service.name
      );

      return acc;
    }, {});

    // Convert the services list into different formats
    for (const categoryName in categorizedServices) {
      const servicesList = categorizedServices[categoryName].services;
      const fullServicesList = categorizedServices[categoryName].fullServiceNames;

      categorizedServices[categoryName].services = createFormats(servicesList);
      categorizedServices[categoryName].fullServiceNames =
        createFormats(fullServicesList);
    }

    const catNames = [...new Set(services.map((s) => s.category_name))];
    const serviceNames = services.map((s) => s.name);
    const fullServiceNames = services.map(
      (s) => s.category_name + " - " + s.name
    );

    categorizedServices = {
      ...categorizedServices,
      services: createFormats(serviceNames),
      fullServiceNames: createFormats(fullServiceNames),
      ...createFormats(catNames),
    };

    return categorizedServices;
  }




  // console.log(clientStatus);

  if (clientStatus == 'loading') {
    return <ErrorComponent><CircularProgress /> <div> Please wait while loading </div></ErrorComponent>;
  } else if (clientStatus == 'serverError') {
    return <ErrorComponent><Typography variant="h6">Oops! Server Error.</Typography></ErrorComponent>;
  } else if (clientStatus == 'responded') {
    return <ErrorComponent><Typography variant="h6">You already filled out the survey.</Typography></ErrorComponent>;
  } else if (clientStatus == 'sent' || clientStatus == 'opened') {

    if (surveyModel && eventVars) {
      if (DEBUG) { console.log(eventVars) }

      const { services = [], products = [], ...otherVars } = eventVars;


      if (services) {
        const serviceObj = formatServicesData(services);

        surveyModel.setVariable("serviceObj", serviceObj);
        surveyModel.setVariable("services", services.map(s => s.name));
        surveyModel.setVariable("serviceIds", services.map(s => s.id));
        surveyModel.setVariable("serviceCategories", services.map(s => s.category_name));
        surveyModel.setVariable("serviceCategoryIds", services.map(s => s.category_id));


      }

      if (products) {
        const productObj = formatServicesData(products);

        surveyModel.setVariable("productObj", productObj);
        surveyModel.setVariable("productIds", products.map(s => s.id));
        surveyModel.setVariable("productCategories", products.map(s => s.category_name));
        surveyModel.setVariable("productCategoryIds", products.map(s => s.category_id));

      }

      for (const varname in otherVars) {
        let varvalue = eventVars[varname];


        surveyModel.setVariable(varname, varvalue);


      }
      if (DEBUG) {
        const vs = surveyModel.getVariableNames();
        vs.forEach(v => console.log(v + ":", surveyModel.getVariable(v)))
      }
      return <Survey model={surveyModel} />;
    } else {
      console.log('Unable to load question ', eventVars)
      return <ErrorComponent><CircularProgress /></ErrorComponent>;

    }
  }
  else {
    return <ErrorComponent><Typography variant="h6"> Error occured ! </Typography></ErrorComponent>;


  }


}

export default SurveyComponent;



// const testeventVars = JSON.parse('{"isMember":true,"visitDate":"6/7/2024","staffName":"Nisha Gurung","staffId":12,"visitType":"appointment","paymentMethod":"prepaid","serviceId":37,"serviceName":"Full Face","serviceCategoryName":"Threading","serviceCategoryId":7,"productIds":[4,1964],"productCategoryIds":[1,2],"productCategoryNames":["Shampoo","Lipstick"],"productNames":["Clear total care","New Test 2"]}')

// const surveyJSON = 