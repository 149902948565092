import {useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import { IconPickerItem } from 'react-fa-icon-picker';
import tinycolor from "tinycolor2";
import './ThankYouPage.css';

function ThankYouPage() {
    const location = useLocation();
    const links = location.state?.links ;
    const designSettings = location.state?.designSettings;
    const logoUrl =  designSettings?.logoUrl;

    useEffect(() => {
        if (logoUrl) {
          const link = document.createElement('link');
          link.rel = 'prefetch';
          link.href = logoUrl;
          document.head.appendChild(link);
        }
        return () => {
          if (logoUrl) {
            const link = document.querySelector(`link[href="${logoUrl}"]`);
            if (link) {
              document.head.removeChild(link);
            }
          }
        };
      }, [logoUrl]);
    

  

    const appLinks = links.filter(link => link.mobileApp);
    const nonAppLinks = links.filter(link => !link.mobileApp);



    

    let brandPrimaryColor = designSettings?.primaryColor;

    const tcolor = tinycolor(brandPrimaryColor);


    

    const [, ter1, ter2 ] = tcolor.analogous();
    const tc = ter2.complement();




    const linkColor = tcolor.darken(2).toHexString();
    const bg = tc.lighten(45).toHexString();





    return (
        <div
        style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        }}
        >

        <div style={{
            background: bg,
            margin:"50px auto",
            borderRadius: "10px",
            display:'flex',
            justifyContent:'center',
            width:'fit-content',
            padding:'20px',

        }}>

       
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height:'80vh',
            maxWidth:'80vw',
            gap:'20px',

        }}>
            <div style={{
                minHeight:'50px',
            }}>
                <img src={ logoUrl ?? "logo512.png"}   alt='business logo' />
            </div>
            <div>
                <h2 style={{
                    textAlign:'center'
                }}>Thank you for completing the survey!</h2>
            </div>


            <div style={{
                 
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '20px',
                   
                
            }} >
                <LinkGroup links={nonAppLinks} linkBgColor={linkColor} />

                {appLinks.length > 0 && <>
                    <h4 style={{textAlign:"center"}}> Download our mobile app  </h4>
                    <LinkGroup links={appLinks} linkBgColor={linkColor}/>

                </>}


            </div>

        </div>
        </div>
        </div>

    );
}

export default ThankYouPage;


const LinkGroup = ({ links , linkBgColor }) => {
    const linkStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '10px 4px 4px 4px',
        color: "inherit",
        textDecoration: "none",
        width: "115px",
        background: linkBgColor,
        borderRadius: "7px",
        color: "#ffd7d7",
    }
    return (
        <div style={{
            display: "flex",
            justifyContent: "center",
            flexWrap:"wrap",
            width: '100%',
            gap: "30px",
        
        }}>
            {links.map((link, index) => (
                <a key={index} href={link.url} style={linkStyle}>
                    <IconPickerItem icon={link.icon} color={'#ffd7d7'} size={40} />
                    <p style={{ textAlign: "center" }}>{link.title}</p>
                </a>
            ))}
        </div>
    );
};


