import { Container, Paper, Typography, CircularProgress } from '@mui/material';



export default function ErrorComponent({ children }) {

    return (<Container component="main" maxWidth="sm" sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100vh', justifyContent: 'center' }}>
        <Paper elevation={3} sx={{ padding: 4, width: '100%', textAlign: 'center' }}>
            {children}
        </Paper>
    </Container>
    )
}