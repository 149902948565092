import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import ErrorComponent from './ErrorComponent';
import SurveyComponent from './SurveyComponent';
import ThankYouPage from './ThankYouPage'; 
import ReactDOM from 'react-dom/client';
// import Hello from './Hello';
import './index.css';
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(

    <React.StrictMode>
      <Router>
        <Routes>
          <Route path="/" element={<ErrorComponent><div> Invalid Page </div></ErrorComponent>} />
          <Route path="/survey/:urlid" element={<SurveyComponent  />} />
          <Route path="/thankyou" element={<ThankYouPage />} />  
          <Route path="*" element={<Navigate to="/" replace />} />
          {/* <Route path="/test/survey" element={ <Hello /> } /> */}
        </Routes>
      </Router>
    </React.StrictMode>
);

